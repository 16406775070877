body {
    background-color: lightgray;
    padding-top: 56px;
    height: 100%;
    /*margin: 0;*/
    /*padding: 0;*/
}

#home_logo{
    width: 40%;
}

#calendar{
    height: 500px;
}

.multiselect{
    float: left;
    margin-right: 10px;
}

#tab_button {
    color: #b9372f;
    font-size: 30px;
}

.nav-link{
    color: #b9372f;
}

.nav-link:hover{
    color: #b9372f;
}

#chart-container {
    position: relative;
    margin: auto;
    height: 60vh;
    width: 60vw;
    /*width: 55%;*/
}

#button{
    border-radius: 0;
    width: 100%;
}

#question_card{
    border-radius: 0;
}

#question_card_link{
    color: red;
}

#question_card_link{
    color: red;
    text-decoration: none;
    border-radius: 0;
}

#login_container{
    margin-top: 60px;
}

#headingOne:hover, #headingTwo:hover, #headingThree:hover, #headingFour:hover, #headingFive:hover{
    border-left: 6px solid red;
}

.fc-timeGridWeek-button{
    background-color: #b1d583;
    background-image: none;
}

.dropdown-item{
    cursor: pointer;
}

#flash{
    width: fit-content;
}

#homepage_buttons{
    margin-bottom: 50px;
}

/* Timer */
#timer {
    color: black;
    font-size: 30px;
}
.timer-label {
    font-size: 20px;
    padding-left: 0.25rem;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem; /* Footer height */
}

#page-container{
    position: relative;
    min-height: 100vh;
    margin-top: 60px;
}

#content-wrap{
    padding-bottom: 2.5rem;    /* Footer height */
}

#register_button{
    background-color: #b9372f;
    color: white;
    float: right;
}

#select_button{
    background-color: #efd782;
    float: right;
    margin-bottom: 100px;
}

/* Hero section */
#home_page {
    background: url('https://www.septintasfortas.lt/wp-content/uploads/2016/08/web-4.jpg') no-repeat center;
}
#learn_more {
    background-color: #b9372f;
    border-color: #b9372f;
}

#link_btn {
    background-color: transparent;
    border-color: #b9372f;
    color: #b9372f;
}

#home_cards_1 {
    background-color: #fcf1aa;
}

#home_cards_2 {
    background-color: #eed682;
}

#home_cards_3 {
    background-color: #f38b49;
}

#home_sm_title {
    font-size: 40px;
    color: #b9372f;
    text-shadow: -3px 1px 2px black;
}

/*#nuorodos{*/
    /*background-color: #c2e3ce;*/
/*}*/

.delete{
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
}

/* card */
.container-fluid .card {
    border-radius: 0;
    box-shadow: 0 1px 15px 1px rgba(69,65,78,.08);
    border: none;
}

.card .card-header {
    border-bottom: 1px solid #ebedf2;
    font-weight: 500;
    color: #575962;
    padding: 1.5rem 2.2rem;
    font-size: 1.1rem;
    background-color: white;
    border-radius: 0;
}
.card-body {
    padding: 2.2rem 2.2rem;
}

.fa-check-circle {
    font-size: 50px;
    padding-bottom: 15px;
}

#success-button {
    background-color: #b9372f;
    border: none;
}

/* confirm button */
.btn.check_conf {
    white-space: normal;
    width: 100px;
    font-size: 12px;
    border-radius: 0;
    border: 1px solid darkgrey;
}

.btn.check_conf:hover {
    background-color: lightgray;
}

#discount{
    padding-left: 100px;
}